<template>
  <main class="container-fluid">
    <b-card class="af-card">
      <b-tabs content-class="mt-3">
        <b-tab title="Users" title-link-class="px-0 mr-3" active>
          <Users />
        </b-tab>
        <b-tab title="Managers" title-link-class="px-0 mr-3">
          <Managers />
        </b-tab>
      </b-tabs>
    </b-card>
  </main>
</template>

<script>
import Users from '../components/users/Users.vue'
import Managers from '../components/users/Managers.vue'

export default {
  metaInfo: {
    title: 'Users',
    titleTemplate: '%s | AirtimeFlip'
  },
  components: {
    Users,
    Managers
  }
}
</script>

<style lang="scss" scoped>

</style>
