<template>
  <div>
    <b-row class="justify-content-between">
      <b-col cols="6" class="mt-4">
        <b-form-group class="af-input">
          <b-input-group class="mb-3">
            <b-form-input v-model="searchTerm" class="af-input-field" required></b-form-input>
            <template v-slot:append>
              <b-input-group-text class="af-input-append">
                <span class="icon-magnifier"></span>
              </b-input-group-text>
            </template>
          </b-input-group>
        </b-form-group>
      </b-col>
      <!-- <b-col cols="4" class="mt-4">
        <b-form-select v-model="selected" :options="options"></b-form-select>
      </b-col> -->
    </b-row>
    <!-- table -->
    <b-table
      id="af-table"
      :items="users || getUsers"
      :fields="fields"
      :filter="filter"
      :perPage="perPage"
      :currentPage="currentPage"
      @row-selected="showProfile"
      hover
      responsive
      borderless
      selectable
      show-empty
    >
      <template #empty>
        <p class="text-center text-muted">No users found</p>
      </template>

      <template v-slot:head()="data">
        <span class="text-uppercase font-weight-bold title">{{ data.label }}</span>
      </template>

      <template v-slot:head(createdAt)>
        <span class="text-uppercase font-weight-bold title">Date</span>
      </template>

      <template v-slot:head(isLoggedIn)>
        <span class="text-uppercase font-weight-bold title">Status</span>
      </template>

      <template v-slot:cell(name)="data">
        <div class="row align-items-center no-gutters">
          <div class="text-uppercase mr-2">
            <span class="initials">{{ computeInitials(data.value) }}</span>
          </div>
          <div>
            <p class="mb-0">{{ data.value }}</p>
            <p class="text-muted text-14 mb-0">USER</p>
          </div>
        </div>
      </template>

      <template v-slot:cell(createdAt)="data">
        <span class="font-weight-bold">{{ formatDate(data.value) }}</span>
      </template>

      <template v-slot:cell(isLoggedIn)="data">
        <span v-if="data.value" class="text-success font-weight-bold text-uppercase">Online</span>
        <span v-else class="text-uppercase text-secondary">Offline</span>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      align="right"
      hide-ellipsis
      hide-goto-end-buttons
      aria-controls="af-table"
    >
      <template v-slot:page="{ page, active }">
        <span class="active" v-if="active">{{ page }}</span>
        <span v-else>{{ page }}</span>
      </template>
    </b-pagination>
  </div>
</template>

<script>
import { format } from 'date-fns'
import { getUsers, searchUsers } from '@/apis/users'

export default {
  data () {
    return {
      currentPage: 1,
      perPage: 25,
      aggregate: 25,
      filter: null,
      users: null,
      fields: [
        'name',
        'email',
        'phone',
        'createdAt',
        'isLoggedIn'
      ],
      selected: null,
      options: [
        { value: null, text: 'Sort by' },
        { value: 'c', text: 'Suspended' },
        { value: 'd', text: 'Verified' }
      ],
      searchTerm: '',
      timeout: ''
    }
  },
  computed: {
    rows () {
      return this.aggregate
    }
  },
  watch: {
    searchTerm (val) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.searchUsers(val)
      }, 1000)
    }
  },
  methods: {
    computeInitials (name) {
      const names = name.split(' ')
      let initials = ''
      for (let i = 0; i < names.length; i++) {
        initials += names[i].charAt(0)
      }
      return initials
    },
    formatDate (date) {
      return format(new Date(date), 'dd/MM/yyyy')
    },
    async getUsers () {
      this.users = null
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
      const query = `page=${this.currentPage}`
      try {
        const { data } = await getUsers(query)
        this.aggregate = data.agreegate
        return data.data
      } catch (error) {
        console.log(error)
      }
    },
    async searchUsers (val) {
      try {
        const { data } = await searchUsers(`filter=true&query=${val}`)
        clearTimeout(this.timeout)
        this.aggregate = data.agreegate
        if (!val) {
          this.users = null
        } else {
          this.users = data.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    showProfile (item) {
      this.$router.push(`/profile/${item[0]._id}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.initials {
  background-color: #3FBBC2;
  color: white;
  padding: 13px;
  border-radius: 50%;
}
.page-item.active .page-link {
  background-color: #F38D04;
  color: #fff;
}
</style>
