<template>
  <div>
    <b-row class="justify-content-between">
      <b-col cols="6" class="mt-4">
        <b-form-group class="af-input">
          <b-input-group class="mb-3">
            <b-form-input v-model="filter" class="af-input-field" required></b-form-input>
            <template v-slot:append>
              <b-input-group-text class="af-input-append">
                <span class="icon-magnifier"></span>
              </b-input-group-text>
            </template>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="5" class="mt-4">
        <b-row class="no-gutters justify-content-end">
          <b-button variant="outline-primary" class="mr-3" @click="addManager">Add Manager +</b-button>
          <!-- modal start -->
          <b-modal v-model="modalOpen" content-class="af-modal" hide-header hide-footer centered>
            <p class="text-uppercase font-weight-bold mb-4">{{ editMode ? 'Edit' : 'Add'}} Manager</p>
            <b-row>
              <b-col cols="12">
                <p class="text-14">FULL NAME</p>
                <b-form-input v-model="selectedManager.name" class="af-input-field mb-4"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="7">
                <p class="text-14">EMAIL</p>
                <b-form-input v-model="selectedManager.email" class="af-input-field mb-4"></b-form-input>
              </b-col>
              <b-col cols="5">
                <p class="text-14">PHONE NUMBER</p>
                <b-form-input v-model="selectedManager.phone" class="af-input-field mb-4"></b-form-input>
              </b-col>
            </b-row>
            <div class="text-right">
              <b-button :disabled="processing" variant="primary" class="px-4" @click="onSubmit">
                <b-spinner small v-if="processing" label="Spinning"></b-spinner>
                <span v-else>Save</span>
              </b-button>
            </div>
          </b-modal>
          <!-- modal end -->
          <!-- <b-form-select v-model="selected" :options="options"></b-form-select> -->
        </b-row>
      </b-col>
    </b-row>
    <!-- table -->
    <b-table
      id="af-table"
      :items="managers"
      :fields="fields"
      :filter="filter"
      :perPage="perPage"
      :currentPage="currentPage"
      @row-selected="showProfile"
      select-mode="single"
      hover
      responsive
      borderless
      selectable
      show-empty
    >
      <template #empty>
        <p class="text-center text-muted">No managers found</p>
      </template>

      <template v-slot:head()="data">
        <span class="text-uppercase font-weight-bold title">{{ data.label }}</span>
      </template>

      <template v-slot:head(managerUserCount)>
        <span class="text-uppercase font-weight-bold title">Users</span>
      </template>

      <template v-slot:cell(name)="data">
        <div class="row align-items-center no-gutters">
          <div class="text-uppercase mr-2">
            <span class="initials">{{ computeInitials(data.value) }}</span>
          </div>
          <div>
            <p class="mb-0">{{ data.value }}</p>
            <p class="text-muted text-14 text-uppercase mb-0">Manager</p>
          </div>
        </div>
      </template>

      <template v-slot:cell(createdAt)="data">
        <span class="font-weight-bold">{{ formatDate(data.value) }}</span>
      </template>

      <template v-slot:cell(email)="data">
        <span class="text-secondary">{{ data.value }}</span>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      align="right"
      hide-ellipsis
      hide-goto-end-buttons
      aria-controls="af-table"
    >
      <template v-slot:page="{ page, active }">
        <span class="active" v-if="active">{{ page }}</span>
        <span v-else>{{ page }}</span>
      </template>
    </b-pagination>

    <!-- manager profile -->
    <b-modal v-model="showProfileModal" content-class="af-modal p-5" size="lg" hide-header hide-footer centered>
      <p class="text-uppercase font-weight-bold mb-4">Manager's Profile</p>
      <b-row class="no-gutters align-items-center mb-4">
        <div class="row align-items-center justify-content-center mr-3">
          <div class="initials-lg">
            <p class="text-36 mb-0">{{ computeInitials(selectedManager.name) }}</p>
          </div>
        </div>
        <div>
          <p class="font-weight-bold text-18">{{ selectedManager.name }}</p>
          <p class="text-14">{{ selectedManager.email }}</p>
          <p class="text-uppercase text-14 text-muted">Account Manager</p>
        </div>
      </b-row>
      <b-row>
        <b-col cols="4">
          <p class="text-14 text-uppercase">Total Users</p>
          <p class="text-14">{{ selectedManager.managerUserCount }}</p>
        </b-col>
        <b-col cols="4">
          <p class="text-14 text-uppercase">Phone Number</p>
          <p class="text-14">{{ selectedManager.phone }}</p>
        </b-col>
        <b-col cols="4">
          <div class="text-right">
            <b-button @click="editManager" variant="outline-primary" class="px-4 w-100 mb-3" size="sm">
              <span>Edit Profile</span>
            </b-button>
            <b-button @click="openDeleteModal = true" variant="outline-danger" class="px-4 w-100" size="sm">
              <span>Delete Manager</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-modal>

    <!-- delete modal -->
    <b-modal v-model="openDeleteModal" content-class="af-modal text-center p-5" hide-header hide-footer centered>
      <p class="text-uppercase font-weight-bold mb-3">Delete Manager</p>
      <p class="mb-4">Are you sure you want to delete {{ selectedManager.name }}?</p>
      <div class="text-center">
        <b-button @click="deleteManager" :disabled="processing" variant="danger" class="px-4 mr-3">
          <span>Delete</span>
        </b-button>
        <b-button @click="openDeleteModal = false" :disabled="processing" variant="empty" class="px-4">
          <span>Cancel</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { format } from 'date-fns'
import { getManagers, createManager, updateManager, deleteManager } from '@/apis/managers'

export default {
  data () {
    return {
      modalOpen: false,
      showProfileModal: false,
      openDeleteModal: false,
      editMode: false,
      currentPage: 1,
      perPage: 10,
      filter: null,
      selectedManager: {
        name: '',
        email: '',
        phone: ''
      },
      managers: [],
      processing: false,
      fields: [
        'name',
        'email',
        'phone',
        'managerUserCount'
      ],
      selected: null
    }
  },
  computed: {
    rows () {
      return this.managers.length
    }
  },
  watch: {
    modalOpen (val) {
      if (!val) {
        this.editMode = false
      }
    }
  },
  created () {
    this.getManagers()
  },
  methods: {
    computeInitials (name) {
      const names = name.split(' ')
      let initials = ''
      for (let i = 0; i < names.length; i++) {
        initials += names[i].charAt(0)
      }
      return initials
    },
    showProfile (item) {
      this.selectedManager = {
        name: item[0].name,
        phone: item[0].phone,
        email: item[0].email,
        managerUserCount: item[0].managerUserCount,
        id: item[0]._id
      }
      this.showProfileModal = true
    },
    addManager () {
      this.selectedManager = {
        name: '',
        phone: '',
        email: ''
      }
      this.modalOpen = true
    },
    editManager () {
      this.showProfileModal = false
      this.editMode = true
      this.modalOpen = true
    },
    formatDate (date) {
      return format(new Date(date), 'dd/MM/yyyy')
    },
    onSubmit () {
      if (this.editMode) {
        this.updateManager()
      } else {
        this.createManager()
      }
    },
    async getManagers () {
      try {
        const { data } = await getManagers()
        this.managers = data.data
      } catch (error) {
        console.log(error)
      }
    },
    async createManager () {
      this.processing = true
      try {
        const payload = {
          name: this.selectedManager.name,
          email: this.selectedManager.email,
          phone: this.selectedManager.phone
        }
        await createManager(payload)
        this.modalOpen = false
        this.processing = false
        this.$bvToast.toast('Manager created successfully', {
          title: 'Notification',
          variant: 'success',
          solid: true
        })
        this.getManagers()
      } catch (error) {
        this.processing = false
        this.$bvToast.toast(error.response.data.error, {
          title: 'Notification',
          variant: 'danger',
          solid: true
        })
        console.log(error)
      }
    },
    async deleteManager () {
      this.processing = true
      try {
        await deleteManager(this.selectedManager.id)
        this.openDeleteModal = false
        this.showProfileModal = false
        this.processing = false
        this.$bvToast.toast('Manager deleted', {
          title: 'Notification',
          variant: 'success',
          solid: true
        })
        this.getManagers()
      } catch (error) {
        this.processing = false
        this.$bvToast.toast(error.response.data.error, {
          title: 'Notification',
          variant: 'danger',
          solid: true
        })
      }
    },
    async updateManager () {
      this.processing = true
      try {
        const payload = {
          name: this.selectedManager.name,
          email: this.selectedManager.email,
          phone: this.selectedManager.phone
        }
        await updateManager(payload, this.selectedManager.id)
        this.modalOpen = false
        this.processing = false
        this.$bvToast.toast('Manager updated successfully', {
          title: 'Notification',
          variant: 'success',
          solid: true
        })
        this.getManagers()
      } catch (error) {
        this.processing = false
        this.$bvToast.toast(error.response.data.error, {
          title: 'Notification',
          variant: 'danger',
          solid: true
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.initials {
  background-color: #F86D70;
  color: white;
  padding: 13px;
  border-radius: 50%;
}
.initials-lg {
  background-color: #F86D70;
  color: white;
  padding: 30px;
  border-radius: 8px;
}
.page-item.active .page-link {
  background-color: #F38D04;
  color: #fff;
}
</style>
